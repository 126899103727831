/* Estilos generales */

@import url("./assets//css/bootstrap_theme.css");
@import url("./assets/fonts/mimetypes/mimetypes.css");
@import url("./assets/css/alertDialog.css");
@import url("./assets/css/views/therapistStyle.css");

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* /assets/css/icomoon/style.css */

/* Full calendar */

/* @import '~@fullcalendar/core/main.css'; */
@import "~@fullcalendar/daygrid/main.css";
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --font-family: "Roboto Slab", serif;
  --font-family: "Poppins", sans-serif;
  background-color: #f9f9f9;
}

a {
  text-decoration: none;
  outline: none;
  border: 0px;
  color: #636262;
  width: 100%;
}

.pointer {
  cursor: pointer;
}

a:hover {
  outline: none;
  text-decoration: none;
  color: #281B65;
}

input:focus,
input.form-control:focus,
.selectLight:focus,
.form-control:focus {
  outline: none !important;
  outline-width: 0 !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  border-color: #281b65 !important;
  background-color: transparent;
}

.form-control {
  background-color: transparent;
  width: inherit;
  font-size: 14px;
  cursor: pointer;
}

.selectLight {
  border: none;
  background-color: transparent;
  width: inherit;
  font-size: 14px;
  cursor: pointer;
}

.prueba {
  outline: none !important;
  outline-width: 0 !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}

.z-index {
  z-index: 9;
}

.uppercase {
  text-transform: uppercase;
}

.shadow-trainfes {
  box-shadow: 0px 0px 21px -7px rgba(0, 0, 0, 0.2);
}

.scroll-box {
  height: 90%;
  width: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
}

.scroll-box:hover {
  height: 90%;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.center-flex {
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.rotate:hover
{
  -webkit-transform: rotateZ(-2deg);
  -ms-transform: rotateZ(-2deg);
  transform: rotateZ(-2deg);
  color: red;
}

/*  Estilos icono color intercalado efecto card*/

.card-records:nth-child(odd) .icon {
  background-color: #fdad34;
}

.card-records:nth-child(even) .icon {
  background-color: #281b65;
}

/* Margin y padding */

.mt-6 {
  margin-top: 6px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-45 {
  margin-top: 45px;
}

.mt-50 {
  margin-top: 50px !important;
}

.mt-70 {
  margin-top: 70px !important;
}

.mt-88 {
  margin-top: 88px !important;
}

.mt-100 {
  margin-top: 100px !important;
}

.mt-120 {
  margin-top: 120px;
}

.mt-150 {
  margin-top: 150px;
}

.mt-200 {
  margin-top: 200px;
}

.mb-12 {
  margin-bottom: 12px;
}

.mb-36 {
  margin-bottom: 36px !important;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-100 {
  margin-bottom: 100px;
}

.ml-75 {
  margin-left: 75px;
}

.p-20 {
  padding: 20px;
}

.pb-tf-5 {
  padding-bottom: 5px;
}

.pt-11 {
  padding-top: 11.5px !important;
}

.pt-100 {
  padding-top: 100px !important;
}

.pt-130 {
  padding-top: 130px !important;
}

/* Colores */

.bg-primario {
  background: #281b65 !important;
}

.bg-primario-100 {
  background: #f3f8ff !important;
}

.bg-primario-300 {
  background-color: #e3eaf4;
}

.bg-secundario {
  background: #fdad34 !important;
}

.bg-secundario-100 {
  background: #ffcf87 !important;
}

.bg-grey {
  background: #f0f0f1;
}

.bg-grey.chat {
  background: #eceff4;
}

.bg-border-primario {
  background: #eceff4;
  border: 1px solid #281b65;
}

.bg-purple {
  background: #5943c1 !important;
}

.bg-title {
  background: #fdad34;
  color: #fff;
  padding: 10px;
  padding-left: 25px;
  width: 200px;
  margin-bottom: 0;
  margin-top: 50px;
}

.color-primario {
  color: #281b65 !important;
}

.color-primario-100 {
  color: #d0c9ff !important;
}

.color-secundario {
  color: #fdad34 !important;
}

.color-secundario-100 {
  color: #ffc671;
}

.color-disabled {
  color: #c4c4c4;
}

.color-sub-text {
  color: #263238;
}

.color-tit-text {
  color: #182024;
}

.color-orange {
  color: #FF7F23;
}

.color-red {
  color: #FD0202;
}

.color-blue {
  color: #4666DD;
}

.color-green {
  color: #49C39E;
}

.border-primario {
  border: 1px solid #281b65;
}

.border-secundario {
  border: 1px solid #fdad34;
}

.border-noti {
  border: 2px solid #d0c9ff;
}

.border-active {
  border: 2px solid #6a5da5 !important;
}

.border-radius-4 {
  border-radius: 4px;
}

.border-light {
  border: 1px solid #ECF2FF !important;
}

.border-dark {
  border: 1px solid #D8E6F4 !important;
}

.border-dark:hover {
  border: 1px solid transparent !important;
}

.circle {
  width: 8px;
  height: 8px;
  border-radius: 100%;
}

.green {
  background-color: #49C39E;
}

.blue {
  background-color: #4666DD;
}

.orange {
  background-color: #FF7F23;
}

.red {
  background-color: #FD0202;
}

/* Tamaño fuente */

.fz-8 {
  font-size: 8px;
}

.fz-9 {
  font-size: 9px;
}

.fz-10 {
  font-size: 10px;
}

.fz-12 {
  font-size: 12px;
}

.fz-13 {
  font-size: 13px;
}

.fz-14 {
  font-size: 14px;
}

.fz-16 {
  font-size: 16px;
}

.fz-18 {
  font-size: 18px;
}

.fz-21 {
  font-size: 21px;
}

.fz-24 {
  font-size: 24px;
}

.fz-26 {
  font-size: 26px;
}

.fz-30 {
  font-size: 30px;
}

.fz-40 {
  font-size: 40px;
}

.fz-60 {
  font-size: 60px;
}

.w-90 {
  width: 80%;
}

.f-10 {
  font-size: 9px;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700;
}

.fw-900 {
  font-weight: 900;
}

/* Estilos cuadrado color (editar color en componente color) */

.box-color {
  background: #618be8;
  border-radius: 3px;
  width: 20px;
  height: 20px;
}

/* Estilos login general */

.MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 2px solid #281b65 !important;
}

/* Estilos nav general */

.table {
  font-size: 10px;
}

nav {
  max-width: 100%;
  background-color: #fff;
  box-shadow: 0px 2px 20px rgba(221, 221, 221, 0.3);
}

.search {
  background-color: #fff;
  outline: none;
}

ul li {
  /*color: #281B65;*/
  font-size: 14px;
  font-weight: 400;
}

/* Estilos card general */

.scroll-card-xs {
  max-height: 10vh;
  overflow-y: auto;
}

.scroll-card-sm {
  max-height: 28vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.scroll-list-p {
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.scroll-list-85 {
  max-height: 85vh;
  overflow-y: auto;
}

.scroll-list-87 {
  max-height: 87vh;
  overflow-y: auto;
}

.card-plataform {
  box-shadow: 0px 2px 20px rgba(221, 221, 221, 0.3);
  min-height: 89vh;
}

.card-tf {
  width: 100%;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 5px 30px rgba(221, 221, 221, 0.6);
  border-radius: 4px;
  cursor: pointer;
}

.card-tf-s {
  width: 100%;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 5px 30px rgba(221, 221, 221, 0.6);
  border-radius: 4px;
}

.card-border {
  border-radius: 3px;
  font-size: 14px;
  padding: 7px 10px;
  background-color: #f8f8fa;
  color: #4632a4;
  z-index: auto;
  width: 100%;
  height: auto;
  border: 1px solid #ece9fc;
}

.box-titulo {
  padding: 20px 10px 20px 10px;
  color: white;
  height: 45px;
}

.box-patient {
  width: 100%;
  height: 54px;
  background: #ffffff;
}

.card-active:hover {
  box-shadow: 0px 1px 30px rgba(221, 221, 221, 1.6);
  font-weight: 500;
}

.card-active-active {
  box-shadow: 0px 1px 30px rgba(221, 221, 221, 1.6);
  font-weight: 500;
  background-color: #f6f4ff;
  border: 1px solid #ece9fc;
}

.card-active-active .icon-arrow-point-to-right::before {
  color: #281b65;
}

/* Estilos Sidebar general */

.main-menu:hover,
nav.main-menu.expanded {
  width: 250px;
  overflow: hidden;
  opacity: 1;
  transition-delay: 1s;
}

.main-menu {
  background: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  left: 0;
  width: 74px;
  overflow: hidden;
  -webkit-transition: width 0.2s linear;
  transition: width 0.2s linear;
  -webkit-transform: translateZ(0) scale(1, 1);
  opacity: 1;
}

.main-menu > ul {
  margin: 7px 0;
}

.main-menu li {
  position: relative;
  display: block;
  width: 250px;
}

.main-menu li > a {
  position: relative;
  width: 255px;
  display: table;
  border-collapse: collapse;
  border-spacing: 0;
  color: #281b65;
  font-size: 13px;
  text-decoration: none;
  -webkit-transform: translateZ(0) scale(1, 1);
  -webkit-transition: all 0.14s linear;
  transition: all 0.14s linear;
  border-top: 1px solid #fdfdfd;
}

.main-menu .nav-icon {
  position: relative;
  display: table-cell;
  width: 75px;
  height: 36px;
  text-align: center;
  vertical-align: middle;
  font-size: 18px;
}

.main-menu .nav-text {
  position: relative;
  display: table-cell;
  vertical-align: middle;
  width: 190px;
}

.main-menu .fb-like {
  left: 180px;
  position: absolute;
  top: 15px;
}

.main-menu > ul.logout {
  position: absolute;
  left: 0;
  bottom: 0;
}

.no-touch .scrollable.hover {
  overflow-y: hidden;
}

.no-touch .scrollable.hover:hover {
  overflow-y: auto;
  overflow: visible;
}

.active-menu {
  color: #281b65 !important;
  background-color: #EAE5FF;
}

.main-menu li:hover > a,
.main-menu li:active,
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus,
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus,
.no-touch .dashboard-page nav.dashboard-menu ul li:hover a,
.dashboard-page nav.dashboard-menu ul li.active a {
  color: #281b65;
  background-color: #F6F4FF;
}

.sidebar-trainfes {
  z-index: 1000 !important;
  height: 100%;
  width: 73px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #fff;
  overflow-x: hidden;
}

.sidebar-ml-75 {
  margin-left: 75px;
}

/*  Estilos icon sidebar */

.icon-menu {
  font-size: 25px;
  outline: none;
  text-decoration: none;
  border: 0px;
}

.fa {
  position: relative;
  display: table-cell;
  width: 80px;
  height: 55px;
  text-align: center;
  top: 17px;
  font-size: 22px;
}

/* Estilos icon card general */

.icon {
  border-radius: 100%;
  width: 45px;
  height: 45px;
  padding: 4px;
  font-size: 25px;
  color: #fff;
  text-align: center;
  display: table;
}

.icon-carrusel {
  border-radius: 100%;
  width: 35px;
  height: 35px;
  padding: 4px;
  font-size: 21px;
  color: #fff;
  text-align: center;
  display: table;
}

/* Estilos icon intensidades numero general */

.channel-intensity {
  border-radius: 100%;
  width: 25px;
  height: 25px;
  line-height: 23px;
  font-size: 12px;
  text-align: center;
  font-weight: 600;
}

/*  Estilos Tabs general */

.MuiTab-wrapper {
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}

.MuiPaper-elevation1 {
  box-shadow: none !important;
}

.MuiTabs-indicator {
  height: 4px !important;
}

.MuiTabs-indicator {
  background-color: #fdad34 !important;
}

.MuiTabs-fixed {
  background-color: #fff !important;
  z-index: 0 !important;
}

/*  Estilos padding card tab general */

.MuiBox-root {
  padding: 0px !important;
}

.MuiBox-root-155 {
  padding: 0px !important;
}

/*  Estilos List general */

.MuiTypography-body1 {
  font-size: 13px !important;
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: #fdad34 !important;
}

.MuiListItem-root {
  padding: 0px !important;
  width: 100% !important;
}

.PrivateSwitchBase-root-26 {
  padding: 6px !important;
}

.MuiSvgIcon-root {
  height: 20px !important;
}

/** Estilos Botones general**/

.btn-tf-simple {
  font-size: 12px;
  border: 2px solid #e5e3e3;
  background: #fff;
  color: #979797;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 6px;
  min-width: 65px;
  box-sizing: border-box;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.75;
  letter-spacing: 1px;
  text-transform: capitalize;
}

.btn-tf-simple:hover {
  border: 2px solid #281b65;
  background: #fff;
  color: #281b65;
}

.btn-tf-xs {
  border: 1px solid #281b65;
  color: #281b65;
  font-size: 12px;
  min-width: 65px;
  box-sizing: border-box;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 6px;
  letter-spacing: 1px;
  text-transform: capitalize;
  background-color: #fff;
}

.btn-tf-xs:hover,
.btn-ctrl-data:hover {
  background-color: #281b65;
  color: #fff;
}

.btn-tf {
  border: 2px solid #281b65;
  color: #281b65;
  padding: 4px 16px;
  font-size: 14px;
  min-width: 130px;
  box-sizing: border-box;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 6px;
  letter-spacing: 1px;
  text-transform: capitalize;
  background-color: #fff;
}

.btn-tf:hover {
  border: 2px solid #fdad34;
  color: #fdad34;
}

.btn-purple-tf {
  border: 2px solid #5943c1;
  color: #fff;
  padding: 4px 16px;
  font-size: 14px;
  min-width: 130px;
  box-sizing: border-box;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 6px;
  letter-spacing: 1px;
  background-color: #5943c1;
  cursor: pointer;
}

.btn-purple-tf:hover {
  border: 2px solid #4632A4;
  color: #fff;
  background-color: #4632A4;
}

.btn-white-tf {
  border: 2px solid #281b65;
  color: #fff;
  padding: 4px 16px;
  font-size: 14px;
  min-width: 130px;
  box-sizing: border-box;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 6px;
  letter-spacing: 1px;
  text-transform: capitalize;
  background-color: #281b65;
}

.btn-white-tf:hover {
  border: 2px solid #3C2A92;
  background-color: #3C2A92;
  color: #FFF;
}

.btn-white-tf-xs {
  border: 2px solid #fff;
  color: #fff;
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 6px;
  letter-spacing: 1px;
  text-transform: capitalize;
  background-color: transparent;
}

.btn-border-tf {
  color: #281b65;
  border: none;
  width: 100%;
  padding: 6px;
  outline: none;
  border-radius: 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.75;
  z-index: 1;
  text-transform: capitalize;
  background-color: #fff;
}

.btn-border-tf:hover {
  background-color: rgba(218, 218, 218, 0.15);
}

.btn-session {
  border: 2px solid #281b65;
  color: #281b65;
  padding: 6¿4px 16px;
  font-size: 14px;
  min-width: 130px;
  box-sizing: border-box;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 6px;
  letter-spacing: 1px;
  text-transform: capitalize;
  background-color: #fff;
}

.btn-session:hover {
  font-weight: 600;
}

.btn-upFiles {
  width: 40px;
  height: 40px;
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%),
    0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
  color: #fff;
  z-index: 1;
  background-color: #281b65;
  border-radius: 50%;
  line-height: 38px;
  text-align: center;
  font-size: 20px;
  cursor: pointer;
  margin: 0;
}

.btn-upFiles:hover {
  background-color: #332477;
}

.tab-btn {
  font-size: 10px;
  border: 1px solid #e5e3e3;
  padding: 4px 19px;
  background: #fff;
  color: #979797;
  font-weight: 500;
  line-height: 1.75;
  border-right: none;
}

.tab-btn:hover {
  /* border: 1px solid #281B65; */
  background: #281b65;
  color: #fff;
}

.graphic-btn {
  font-size: 10px;
  border: 2px solid #e5e3e3;
  padding: 3px 6px;
  background: #fff;
  color: #979797;
  font-weight: 500;
  line-height: 1.75;
  margin: 3px;
  height: 40px;
  border-radius: 3px;
}

.graphic-btn:hover {
  border: 2px solid #281b65;
  background: #fff;
  color: #281b65;
}

.btn-carrusel {
  height: 25px;
  width: 25px;
  padding: 1px 4px;
  border: none;
  color: #281b65;
  background-color: #f0f0f0;
  border-radius: 6px;
}

.btn-carrusel:hover {
  background-color: #edeaff;
}

.btn-square {
  background-color: #edeaff;
  border: 2px solid #edeaff;
  border-radius: 6px;
  width: 40px;
  height: 40px;
  color: #281b65;
  cursor: pointer;
  text-align: center;
  font-size: 18px;
  margin: 0;
  line-height: 40px;
  text-align: center;
  display: table;
}

.btn-square:hover {
  border: 2px solid #281b65;
  color: #281b65;
  background: #fff;
}

.btn-tf:focus,
.btn-white-tf:focus,
.btn-border-tf:focus,
.btn-session:focus,
.btn-tf-xs:focus,
.tab-btn:focus,
.graphic-btn:focus,
.btn-tf-simple:focus,
.btn-carrusel:focus,
.btn-square:focus {
  outline: none;
}

/* Estilos tabla roles */

.MuiTableCell-body {
  color: #3a3a3a;
}

.MuiTableCell-root {
  border-bottom: 0.5px solid #f0f0f0 !important;
  padding: 8px !important;
  text-align: center !important;
}

.MuiPaper-elevation2 {
  box-shadow: none !important;
}

@media screen and (max-width: 880px) {
  .sidebar-trainfes {
    display: none;
  }
  .ml-75 {
    margin-left: 0px;
  }
  .mb-65-xs {
    margin-bottom: 65px !important;
  }
  .sidebar-ml-75 {
    margin-left: 0;
  }
}

/* Estilos Session Studio */

.card-function {
  width: 85px;
  height: 85px;
  background: #ffffff;
  color: #281b65;
  border-radius: 3px;
  padding: 5px;
  cursor: move;
  letter-spacing: 0.7px;
  position: relative;
}

.card-function:hover {
  background: #fdad34;
  color: #fff;
  border: 1px solid #fdad34;
}

.card-function:hover p {
  color: #fff !important;
}

.icon-function {
  font-size: 40px;
  text-align: center;
}

.time-function {
  width: 180px;
  margin-top: 15px;
}

.position-function {
  border-radius: 100%;
  width: 25px;
  height: 25px;
  line-height: 23px;
  font-size: 12px;
  text-align: center;
  font-weight: 600;
  margin: 0 10px;
}

.closed-box {
  position: absolute;
  right: 30px;
  top: -20px;
  font-size: 10px;
  color: #9AA2A8;
  cursor: pointer;
}

.closed-box:hover {
  color: #281B65;
}

.relative {
  position: relative;
}

.num-pos {
  position: absolute;
  margin: 0 auto;
  top: -30px;
  left: 0;
  right: 0;
}

.height-box {
  height: 87vh;
}

.pruebahelen {
  height: 100%;
}

.modal-tf {
  min-width: 600;
  z-index: 1;
  max-height: "calc(100% - 64px)";
  margin: "32px";
  background: "#FFF";
  border-radius: 4;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

/* Estilos Radio Button (Iconos de bloques) */

input[type="radio"] {
  display: none;
}

label.select {
  text-align: center;
  width: 50%;
  padding: 0 8px 8px;
  background: #ffffff;
  color: #696094;
  border-radius: 0px;
  cursor: pointer;
  border-bottom: 4px solid #e7ecf4;
  margin: 0;
  font-size: 14px;
  font-weight: 400;
}

input[type="radio"]:checked + label.select {
  color: #281b65;
  border-bottom: 4px solid #fdad34;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}

label.radio-alert {
  text-align: center;
  background: #ffffff;
  color: #281b65;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid #281b65;
  margin: 0;
  padding: 15px;
  line-height: 0px;
}

input[type="radio"]:checked + label.radio-alert {
  border: 1px solid #fdad34;
  background-color: #fdad34;
  color: #fff;
}

/* Estilos Radio Button basicos (Modal datos clínicos detalle en vista seguimiento) */

label.radio-button {
  text-align: center;
  color: #281b65;
  border-radius: 100%;
  cursor: pointer;
  height: 10px;
  width: 10px;
  margin: 0;
}

input[type="radio"]:checked + label.radio-button {
  border: 1px solid #fdad34;
  background-color: #fdad34;
  color: #fff;
}

.radio-btn {
  border: 1px solid #91A4CF;
  width: 18px;
  height: 18px;
  border-radius: 100%;
}

.MuiCheckbox-root, .MuiRadio-root {
  color: #9CB2CD !important;
}

.MuiRadio-colorSecondary.Mui-checked {
  color: #fdad34 !important;
}

/* Estilos icono lleva sensor en bloques */

.addsensor {
  position: absolute;
  right: 1px;
  bottom: 0px;
}

/* Estilos icono lleva sensor en bloques final */

/* Estilos Time picker */

.time-picker {
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  padding: 5px;
}

.time-picker:focus {
  outline: none;
  border: 2px solid #4632a4;
  padding: 4px;
}

/* Estilos Checkbox Button (General) */

label.check {
  border: 2px solid #ffd89c;
  color: #fff;
  width: 20px;
  height: 20px;
  font-size: 15px;
  padding: 1px;
  text-align: center;
  line-height: 18px;
  cursor: pointer;
}

input[type="checkbox"]:checked + label.check {
  background-color: #fdad34;
  color: #fff;
  border: 2px solid #fdad34;
}

/* Estilos Checkbox Button (Select Channels) */

input[type="checkbox"] {
  display: none;
}

label.secundario {
  border: 1px solid #fdad34;
  background-color: #fdad34;
  color: #fff;
  border-radius: 100%;
  width: 25px;
  height: 25px;
  line-height: 23px;
  font-size: 12px;
  text-align: center;
  font-weight: 600;
  margin: 0 7px;
  cursor: pointer;
}

label.primario {
  border: 1px solid #281b65;
  background-color: #281b65;
  color: #fff;
  border-radius: 100%;
  width: 25px;
  height: 25px;
  line-height: 23px;
  font-size: 12px;
  text-align: center;
  font-weight: 600;
  margin: 0 7px;
  cursor: pointer;
}

.configChannels label.secundario {
  border: 1px solid #fdad34;
  background-color: #fff;
  color: #fdad34;
  border-radius: 100%;
  width: 25px;
  height: 25px;
  line-height: 23px;
  font-size: 12px;
  text-align: center;
  font-weight: 600;
  margin: 0 7px;
  cursor: pointer;
}

.configChannels label.primario {
  border: 1px solid #281b65;
  background-color: #fff;
  color: #281b65;
  border-radius: 100%;
  width: 25px;
  height: 25px;
  line-height: 23px;
  font-size: 12px;
  text-align: center;
  font-weight: 600;
  margin: 0 7px;
  cursor: pointer;
}

input[type="checkbox"]:checked + label.secundario {
  background-color: #fdad34;
  color: #fff;
}

input[type="checkbox"]:checked + label.primario {
  background-color: #281b65;
  color: #fff;
}

.boxChannels .text-white {
  display: inline-block;
}

.configChannels.vertical .text-white {
  display: block;
}

.configChannels.horizontal .text-white {
  display: inline-block;
}

.chorizontal .ib {
  display: inline-block;
}

.chorizontal {
  margin: 0 auto;
  width: 210px;
}

.configChannels.horizontal {
  width: 190px;
  background: #fff;
  border-radius: 100px;
  margin: 0px auto;
  border: 0.5px solid #f0f0f0;
  filter: drop-shadow(0px 0px 8px rgba(196, 196, 196, 0.3));
}

.configChannels.vertical {
  height: auto !important;
  background: #fff;
  border-radius: 100px;
  border: 0.5px solid #f0f0f0;
  filter: drop-shadow(0px 0px 8px rgba(196, 196, 196, 0.3));
}

.configChannels.vertical .text-white label,
.configChannels.horizontal .text-white label {
  margin: 3px !important;
}

.boxChannels {
  background: #fff;
  margin: 0 auto;
  width: 290px;
  padding: 0 0 10px;
}

/* Estilos Checkbox Button (Select Channels sensor) */

.configChannels label.disabled {
  border: 0.5px solid #eceff1;
  background-color: #eceff1;
  color: #636262;
  border-radius: 100%;
  width: 25px;
  height: 25px;
  line-height: 23px;
  font-size: 12px;
  text-align: center;
  font-weight: 600;
  margin: 0 10px;
  cursor: pointer;
  pointer-events: none;
}

.sensor {
  width: 120px;
  position: absolute;
  top: -177px;
  height: 170px;
  background: #fff;
  margin: 10px;
  border-radius: 5px;
  padding: 10px;
  border: 0.5px solid #cccccc;
}

.block {
  display: block !important;
}

.sensor-box {
  position: relative;
  width: 140px;
}

.led {
  width: 10px;
  height: 10px;
  background: #fdad34;
  border-radius: 100px;
  display: block;
  margin: 20px auto;
  box-shadow: 0px 0px 10px #ffc909;
}

.w300 {
  width: 300px;
}

.boxChannels label,
.configChannels label {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 12px;
  font-weight: 600;
}

.boxBottom {
  margin-bottom: 10px;
}

.timepickers .rc-time-picker {
  width: 100% !important;
  border-radius: 0px;
  border-radius: 5px;
}

.timepickers .rc-time-picker-input {
  color: #281b65;
}

.timepickers .rc-time-picker-clear-icon:after {
  font-weight: 700;
  color: #281b65;
  content: "X";
}

.box-slider {
  padding: 5px 40px 5px 30px;
  max-width: 336px;
  margin: 0 auto;
}

.box-slider span {
  margin: 3px !important;
}

.box-slider .MuiSlider-thumb {
  margin: -5px !important;
}

/* Estilos Slider */

.slider-value {
  height: 30px;
  width: 30px;
  background: #3f2c97;
  border-radius: 50px;
  text-align: center;
  color: #fff;
  line-height: 30px;
  font-size: 14px;
  margin: 0;
}

.MuiSlider-root {
  color: #3f2c97 !important;
}

.card-modes {
  box-shadow: 0px 5px 30px rgba(221, 221, 221, 0.6);
  border-radius: 4px;
  padding: 20px;
  background-color: #fff;
  margin-bottom: 25px;
}

.card-modes-xs {
  box-shadow: 0px 5px 30px rgba(221, 221, 221, 0.6);
  border-radius: 4px;
  padding: 20px;
  background-color: #fff;
}

.min-height .card-modes {
  min-height: 277px;
}

.selectbody .body,
.selectbody .bodyDisplay {
  width: 200px;
  position: relative;
  margin: 0 auto;
}

.selectbody .body *,
.selectbody .bodyDisplay * {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.selectbody .body img {
  max-width: 160px;
  max-height: 350px;
  margin: 0 auto;
  display: block;
}

.selectbody .bodyDisplay img {
  max-width: 160px;
  max-height: 283px;
  margin: 0 auto;
  display: block;
}

.selectbody .select-img .button-select {
  display: inline-block;
  cursor: pointer;
  margin: 10px;
  overflow: hidden;
  width: 50px;
  height: 50px;
  padding: 5px;
}

.button-select {
  cursor: pointer;
}

.button-select:hover {
  background-color: #f8f6ff;
}

.selectbody .select-img {
  margin: 0 auto;
  width: 280px;
}

.selectbody .select-img .button-select.active {
  box-shadow: 0px 2px 19px -5px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
}

/*  Estilos select body (musculos)*/

.checkButtonBody label {
  background: #fff;
  border: 1px solid #281b65;
  color: #281b65;
  border-radius: 100px;
  height: 25px;
  width: 25px;
  text-align: center;
  font-size: 12px;
  padding: 1.5px;
  font-weight: 500;
  cursor: pointer;
}

/* .checkButtonBody input:checked + label {
	background:#281B65;
	color: #fff;
	border:2px solid #281B65;
} */

.checkButtonBody input.primary:checked + label {
  background: #281b65;
  color: #fff;
  border: 2px solid #281b65;
}

.checkButtonBody input.secondary:checked + label {
  background: #fdad34;
  color: #fff;
  border: 2px solid #fdad34;
}

.icon-grey {
  border-radius: 100%;
  width: 35px;
  height: 35px;
  line-height: 35px;
  font-size: 12px;
  color: #c4c4c4;
  text-align: center;
  cursor: pointer;
}

.icon-grey:hover {
  color: #fff;
  background-color: #281b65;
}

.card-icons {
  width: 100px;
  height: 100px;
  background: #ffffff;
  color: #281b65;
  border-radius: 3px;
  padding: 5px;
  cursor: pointer;
  border: 1px solid #281b65;
}

.card-icons:hover {
  color: #fff;
  background-color: #fdad34;
  border: 1px solid #fdad34;
}

.hover-me {
  width: 450px;
  margin: 55vh auto;
  text-align: center;
  position: relative;
}

.hover-me > p {
  font-size: 32px;
  font-style: italic;
  text-decoration: underline;
  cursor: pointer;
}

.hover-me:hover .tooltip {
  opacity: 1;
  top: -180%;
}

.tooltip {
  font-size: 18px;
  background: #345ef2;
  color: white;
  border-radius: 12px;
  position: absolute;
  left: 50%;
  margin-left: -100px;
  pointer-events: none;
  opacity: 0;
  top: -100%;
  -webkit-transition: all 0.25s cubic-bezier(0, 0, 0, 1);
  transition: all 0.25s cubic-bezier(0, 0, 0, 1);
}

.tooltip .message {
  position: relative;
  padding: 18px 60px;
}

.tooltip .bottom-tip {
  width: 40px;
  height: 40px;
  background: #345ef2;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  left: 50%;
  margin-left: -20px;
  top: 40%;
  z-index: -1;
}

/* Estilos Acordeon musculos*/

.MuiExpansionPanel-root:before {
  height: 0 !important;
}

.boxChannelName {
  background: #fafafa;
  width: 313px;
}

.boxChannelName p {
  font-size: 14px;
  color: #979797;
  margin: 0;
}

.boxChannelName table {
  border-collapse: separate;
  border-spacing: 8px;
}

label.primario.checked {
  background-color: #281b65;
  color: #fff;
}

label.secundario.checked {
  background-color: #fdad34;
  color: #fff;
}

.no-events {
  pointer-events: none;
}

.TimePickers {
  padding: 10px 5px;
  width: 100%;
  display: flex;
}

.TimePickers .lcd {
  border-radius: 3px 0px 0px 3px;
  height: 50px;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.23);
  width: 100%;
  /*border-right: 1.5px solid #FDAD34;*/
}

.TimePickers .lcd span {
  position: absolute;
  font-size: 18px;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 14px;
}

.TimePickers .lcd p {
  font-size: 9px;
  right: 8px;
  top: 0;
  margin: 0;
  color: #979797;
}

.TimePickers .controls {
  display: inline-block;
}

.TimePickers .controls button {
  display: block;
  background-color: #281b65;
  color: #fff;
  border: 0.5px solid #281b65;
  outline: 0;
  font-weight: 900;
  font-size: 14px;
  padding: 0;
  margin: 0;
  height: 25px;
  width: 26px;
}

.TimePickers .controls button:hover {
  background-color: #edeaff;
  color: #281b65;
  border: 0.5px solid #edeaff;
}

/*.TimePickers .controls button.plus {
	border-radius: 0px 3px 0 0;
}

.TimePickers .controls button.minus {
	border-radius: 0 0 3px 0px;
} */

.divider-column {
  border: 1px solid #eee;
  margin: 10px;
  height: 40px;
  width: 2px;
  position: absolute;
}

.bbr {
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
  padding: 5px;
}

.btl {
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
  padding: 5px;
}

.bbl {
  border-bottom: 1px solid #ccc;
  border-left: 1px solid #ccc;
  padding: 5px;
}

.btr {
  border-top: 1px solid #ccc;
  border-right: 1px solid #ccc;
  padding: 5px;
}

/* Estilos Card Calendario */

.card-calendar {
  border-radius: 3px;
  border: none;
  cursor: move;
  font-size: 12px;
  margin: 3px 0;
  padding: 7px 10px;
  background-color: #c0b5f2;
  color: #fafafa;
  z-index: auto;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  font-weight: 700;
}

.card-calendar-v {
  border-radius: 3px;
  border: none;
  font-size: 14px;
  margin: 3px 0;
  padding: 7px 10px;
  background-color: #c0b5f2;
  color: #4632a4;
  border-left: 3px solid #4632a4;
  z-index: auto;
  width: 100%;
  height: auto;
  cursor: pointer;
}

.card-calendar-n {
  border-radius: 3px;
  font-size: 14px;
  margin: 3px 0;
  padding: 7px 10px;
  background-color: #f8f8fa;
  color: #4632a4;
  border-left: 3px solid #4632a4;
  z-index: auto;
  width: 100%;
  height: auto;
  border-top: 1px solid #ece9fc;
  border-right: 1px solid #ece9fc;
  border-bottom: 1px solid #ece9fc;
}

.shadow-list-calendar {
  margin-top: -40px;
  height: 40px;
  width: auto;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1)
  );
  position: relative;
}

.box-titulo-calendar {
  color: white;
  height: 40px;
  max-height: 100px;
}

.title-calendar {
  font-size: 14px;
  color: #495057;
  font-weight: 600;
}

.scroll-list-calendar {
  max-height: 23.5vh;
  overflow-y: auto;
}

.scroll-list-functions {
  max-height: 83vh;
  overflow-y: auto;
}

.scroll-table-blocks {
  max-height: 44vh;
  overflow-y: auto;
}

/* Estilos canales grilla sensor */

ul.btns-ch {
  padding: 0 0 10px;
  margin: 0;
}

ul.btns-ch li {
  display: inline-block;
  height: 25px;
  width: 25px;
  color: #fff;
  border-radius: 100%;
  text-align: center;
  margin: 0 10px;
  font-weight: 400;
  cursor: pointer;
  line-height: 23px;
  font-size: 12px;
}

ul.btns-ch li:nth-child(odd) {
  background-color: #281b65;
}

ul.btns-ch li:nth-child(even) {
  background-color: #fdad34;
}

/* Estilos Grilla sensor */

.numGrid.primary {
  color: #281b65;
  font-weight: 600;
}

.numGrid.secondary {
  color: #fdad34 !important;
  font-weight: 600;
}

/* autocomplete */

.react-tags__search-input input {
  width: 100% !important;
  background: #fff !important;
  border-radius: 4px;
  padding: 8px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  height: 40px;
}

.react-tags__suggestions {
  background-color: #fff;
}

.react-tags__suggestions ul {
  padding: 0;
  margin: 0;
}

.react-tags__suggestions li {
  list-style: none;
  padding: 8px;
}

.react-tags__selected-tag {
  overflow: hidden;
  text-align: left;
  border-radius: 3px;
  border: none;
  cursor: move;
  font-size: 14px;
  margin-bottom: 5px;
  padding: 7px 10px;
  background-color: #edeaff;
  color: #3a3a3a;
  /*border-left: 3px solid #4632A4;*/
  z-index: auto;
  width: 100%;
  height: 40px;
}

/* List-videos Calendar */

.list-videos li {
  list-style: none;
  padding: 8px;
}

.list-videos li p {
  font-size: 12px;
}

.list-videos li span {
  font-weight: 20px;
  cursor: pointer;
}

/* Estilos Select sombra */

.MuiPaper-elevation8 {
  box-shadow: 0px 5px 30px rgba(221, 221, 221, 0.6) !important;
}

/* Estilos z-index tabla Terapeutas */

.MuiTableCell-stickyHeader {
  z-index: 0 !important;
}

/* Estilos Notificación y Perfil*/

.MuiBadge-badge {
  height: 20px !important;
  padding: 0 !important;
  font-size: 10px !important;
  min-width: 20px !important;
  cursor: pointer;
  background-color: darkorange !important;
  border-radius: 100% !important;
  margin-top: 3px !important;
}

.icon-display {
  display: table-cell;
  vertical-align: middle;
}

.fc * {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.fc-button-primary:not(:disabled):active,
.fc-button-primary:not(:disabled).fc-button-active {
  background: #281b65;
}

.react-tags__search-input input:focus {
  outline: 0 !important;
  border: 1px solid #4632a4 !important;
}

.Mui-focused fieldset {
  outline: 0 !important;
  border: 1px solid #4632a4 !important;
}

.MuiInputBase-formControl:hover fieldset {
  border: 1px solid #dadce0 !important;
}

.not-focus:focus {
  outline: none;
  cursor: default;
  background-color: transparent;
}

/*input:focus, input.form-control:focus, input:active, input.form-control:active {
	outline:none !important;
    outline-width: 0 !important;
    box-shadow: none !important;
    -moz-box-shadow: none !important;
	-webkit-box-shadow: none !important;
	outline: none;
  	background-color: #FFF!important;
  	border: 1px solid #dadce0;;
}*/

input:-internal-autofill-selected {
  background-color: #fff !important;
}

.hover:hover {
  color: #281b65;
  font-weight: 600;
}

.border-tf {
  border: 0.5px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
}

.border-t {
  border: 0.5px solid rgb(218 218 218 / 23%);
}

.border-top-tf {
  border-top: 0.5px solid #f0f0f0;
}

.border-bottom-tf {
  border-bottom: 0.5px solid #f0f0f0;
}

.border-left-tf {
  border-left: 0.5px solid #f0f0f0;
}

.border-right-tf {
  border-right: 0.5px solid #f0f0f0;
}

.border-table-records {
  border-right: 1px solid rgba(0, 0, 0, 0.23);
  border-left: 1px solid rgba(0, 0, 0, 0.23);
  border-bottom: 1px solid rgba(0, 0, 0, 0.23);
}

.border-radius-3 {
  border-radius: 3px;
}

.border-radius-30 {
  border-radius: 30px;
}

.icon-info-xs {
  width: 25px;
  height: 25px;
  background: #edeaff;
  border-radius: 100%;
  color: #281b65;
  font-size: 18px;
  text-align: center;
  padding: 0px;
  display: table;
}

.icon-info {
  width: 35px;
  height: 35px;
  background: #edeaff;
  border-radius: 100%;
  color: #281b65;
  font-size: 18px;
  text-align: center;
  padding: 0px;
  display: table;
}

.icon-info-lg {
  width: 35px;
  height: 35px;
  background: #edeaff;
  border-radius: 100%;
  color: #281b65;
  font-size: 24px;
  text-align: center;
  padding: 0px;
  display: table;
}

.icon-info-a {
  width: 35px;
  height: 35px;
  background: #fdad34;
  border-radius: 100%;
  color: #fff;
  font-size: 22px;
  text-align: center;
  padding: 0px;
  display: table;
}

.icon-info-z {
  width: 35px;
  height: 35px;
  background: #281b65;
  border-radius: 100%;
  color: #fff;
  font-size: 22px;
  text-align: center;
  padding: 0px;
  display: table;
}

.icon-progress {
  width: 35px;
  height: 35px;
  background: #f5f7fa;
  border-radius: 100%;
  color: #281b65 !important;
  font-size: 9px !important;
  text-align: center;
  padding: 0px;
  display: table;
}

.text-notify {
  max-width: 300px !important;
  white-space: normal;
}

.notify-not-see {
  width: 8px;
  height: 8px;
  padding: 0 !important;
  margin: 0;
  border-radius: 100%;
  background-color: #fdad34;
}

.file-span {
  position: relative;
  top: -5px;
  left: -10px;
  font-size: 14px;
  color: "#fff";
  padding: 0;
  margin: 0;
  font-family: var(--font-family) !important;
}

/* todas las primeras letras de los elementos dentro de card se ponen en mayusculas */

[class^="card-"] p:first-letter,
[class^="card-"] h1:first-letter,
[class^="card-"] h2:first-letter,
[class^="card-"] h3:first-letter,
[class^="card-"] h4:first-letter,
[class^="card-"] h5:first-letter,
[class^="card-"] h6:first-letter,
[class^="card-"] label:first-letter,
[class^="card-"] a:first-letter {
  text-transform: uppercase !important;
}

/* lista de archivo card session */

.files-session {
  padding: 0;
  margin: 0;
  width: 95% !important;
}

.files-session li {
  list-style: none;
  margin: 0px;
  width: 100% !important;
}

.files-session a {
  width: 100%;
  display: block;
  padding: 15px 5px !important;
}

.files-session li:hover {
  background-color: rgba(0, 0, 0, 0.06);
}

.MuiListItem-button:hover {
  background-color: rgba(0, 0, 0, 0) !important;
}

/* Dashboard paciente */

.info-graphic {
  width: 60px;
  height: 10px;
}

/* Vista User Profile */

.nav-pills .nav-link {
  background-color: #ECF2FF;
  border-radius: 4px 4px 0px 0px;
  color: #273142;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #FFF;
  background-color: #4632A4;
  font-weight: 600;
  border-radius: 4px 4px 0px 0px;
}

.nav-pills .nav-link:hover {
  background-color: #E8EFFC;
  border-radius: 4px 4px 0px 0px;
  color: #273142;
}

/*
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #4632a4;
  background-color: #f3f6fb;
  border-left: 4px solid #4632a4;
  font-weight: 600;
  border-radius: 0;
}

.nav-pills .nav-link:hover {
  background-color: #fafafa;
  border-left: 4px solid #e3e3e3;
  border-radius: 0;
  color: #3c4043;
}
*/

.shadow-left {
  -webkit-box-shadow: -8px 1px 19px 0px rgba(245, 245, 245, 1);
  -moz-box-shadow: -8px 1px 19px 0px rgba(245, 245, 245, 1);
  box-shadow: -8px 1px 19px 0px rgba(245, 245, 245, 1);
}

.shadow-right {
  -webkit-box-shadow: 13px 1px 19px 0px rgba(245, 245, 245, 1);
  -moz-box-shadow: 13px 1px 19px 0px rgba(245, 245, 245, 1);
  box-shadow: 13px 1px 19px 0px rgba(245, 245, 245, 1);
}

.input-tf {
  padding: 5px 0px;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.26);
}

/* Estilos Sensor Visualización pacientes */

.channel-horizontal {
  height: 25px;
  width: 25px;
  border-radius: 100%;
  display: inline-block;
  text-align: center;
  margin: 0 4px;
  color: #ccc;
  padding: 3px;
  line-height: 19px;
}

.channels-horizontal {
  border: 0.5px solid #f0f0f0;
  border-radius: 50px;
  padding: 4px;
  width: 210px;
  text-align: center;
}

.channel-vertical {
  height: 25px;
  width: 25px;
  border-radius: 100%;
  text-align: center;
  margin: 4px 0;
  color: #ccc;
  padding: 3px;
  line-height: 19px;
}

.channels-vertical {
  border: 0.5px solid #f0f0f0;
  border-radius: 50px;
  padding: 4px;
  height: auto;
  width: 35px;
  text-align: center;
}

.sensor-patient {
  height: auto;
  height: 180px;
  padding: 10px;
  margin: 14px;
  border-radius: 5px;
  border: 0.5px solid #f0f0f0;
}

/*  Estilos modal extended */

.MuiToolbar-regular {
  min-height: 45px !important;
}

/* Clases para buscar daschboard home terapeuta */

.searchHome {
  position: relative;
  border-radius: 3px;
  background-color: #f9f9f9;
  margin-left: 0;
  width: 100%;
}

.searchIcon {
  padding: 2px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Clases para progress Circular */

.MuiCircularProgress-root {
  height: 35px !important;
  width: 35px !important;
}

.text-progress .MuiTypography-root {
  color: #281b65 !important;
  font-size: 0.7rem !important;
}

.is-active {
  background: #edeaff;
  color: #281b65;
  border-left: 3px solid #281b65;
}

/* Clases para Chat TrainFES */

.avatar {
  height: 35px;
  width: 35px;
  background-color: #edebf8;
  border-radius: 100%;
}

.bottom-position {
  position: absolute;
  bottom: 20px;
}

.fc-time {
  display: none;
}

.ctrls-io {
  padding: 0;
  margin: 0;
}

.ctrls-io li {
  display: inline-flex;
  margin: 20px;
}

.btn-ctrl-tf-white {
  border: 1px solid #fff;
  color: #281b65;
  padding: 6px 16px;
  font-size: 14px;
  min-width: 100px;
  box-sizing: border-box;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 3px;
  letter-spacing: 0.02857em;
  text-transform: capitalize;
  background-color: #fff;
  width: 150px;
}

.btn-ctrl-tf-white:hover {
  color: #281b65;
  background-color: #fff;
  border-color: #281b65;
}

.btn-ctrl-tf {
  border: 1px solid #fff;
  color: #fff;
  padding: 6px 16px;
  font-size: 14px;
  min-width: 100px;
  box-sizing: border-box;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 3px;
  letter-spacing: 0.02857em;
  text-transform: capitalize;
  background-color: #281b65;
  width: 150px;
}

.btn-ctrl-tf:hover {
  color: #281b65;
  background-color: #fff;
  border-color: #281b65;
}

/*  Tag */

.react-tagsinput-tag {
  background-color: #E5E7FA;
  border-color: #E5E7FA;
  color: #6979F8;
  font-weight: 600;
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 4px;
}

.react-tagsinput-remove {
  background-color: #6979F8;
  color: #E5E7FA;
  border-radius: 100%;
  margin-left: 10px;
  font-size: 12px;
  padding-right: 3.5px;
  padding-bottom: 1px;
}

.react-tagsinput--focused {
  border-color: #4632a4;
}

/*  refactorizar y modularizar */

.text-recom {
  color: #000;
  font-size: 24px;
}

.subtext-recom {
  color: #3a3a3a;
  font-size: 16px;
}

.selections-radio {
  background-color: #f6f6f6;
  padding: 20px;
}

.selections-radio li {
  list-style: none;
}

.selections-radio input:checked + label {
  background-color: #281b65;
  color: #fff;
}

.selections-radio label {
  background-color: #e9e5ff;
  border-radius: 3px;
  padding: 8px 10px;
  color: #3a3a3a;
}

.no-arrows-input::-webkit-inner-spin-button,
.no-arrows-input::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}

.counter-input {
  position: absolute;
  font-size: 18px;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 14px;
  width: 100%;
  border: none;
  text-align: center;
  color: #281b65;
  font-weight: 600;
}

.head-table {
  border: 1px solid rgba(0, 0, 0, 0.23);
  height: 60px;
  background-color: #fafafa;
}

.head-table2 {
  border: 1px solid rgba(0, 0, 0, 0.23);
  height: 120px;
  background-color: #fafafa;
}

.head-table3 {
  border: 1px solid rgba(0, 0, 0, 0.23);
  height: 45px;
  background-color: #fafafa;
  border-radius: 3px;
}

.chatView {
  height: calc(100vh - 50px);
}

.chatView .listUsers {
  height: 100%;
  background: #f8f9fd;
  position: relative;
}

.chatView .boxChat {
  height: 100%;
}

.list-percents-table {
  margin: 0;
  padding: 0;
}

.list-percents-table li {
  list-style: none;
  display: inline-block;
  margin: 0 15px;
}

.table-buttons.active {
  background-color: #d0c9ff30 !important;
  color: #281b65 !important;
}

.tab-btn.active {
  background: #281b65;
  color: #fff;
}

.ds input:disabled {
  color: #000 !important;
}

.DatePicker {
  width: 100% !important;
}

.DatePicker__input {
  width: 100% !important;
  height: 49px;
  border-bottom: 4px solid #fdad34;
  border-top: none;
  border-right: none;
  border-left: none;
  cursor: pointer;
}

.file-container {
  height: 100%;
  /* min-width: 100%; */
  width: 100%;
  /* overflow-y: hidden;
    position: relative; */
}

.thumnail .react-pdf__Page canvas {
  width: 100% !important;
  height: 100% !important;
}

.view .react-pdf__Page canvas {
  width: 100% !important;
  height: 100% !important;
  border-top: none;
  border-right: none;
  border-left: none;
}

.grafic {
  height: 330px;
  /*border: 1px solid #CCCCCC;*/
  border-radius: 4px;
}

/* Estilos editor texto */

.rdw-option-wrapper:active {
  background-color: #d0c9ff;
  box-shadow: none;
  color: #fff;
}

.rdw-option-active {
  background-color: #d0c9ff;
  box-shadow: none !important;
  color: #fff;
}

.rdw-option-wrapper:hover,
.rdw-dropdown-selectedtext:hover,
.rdw-dropdown-wrapper:hover {
  box-shadow: none !important;
  background-color: #edeaff;
}

.rdw-editor-toolbar {
  border: 1px solid rgba(0, 0, 0, 0.23);
  font-size: 12px;
  margin-bottom: 0;
  border-radius: 4px 4px 0px 0px;
}

.rdw-option-wrapper {
  border: none;
}

.rdw-emoji-modal,
.rdw-colorpicker-modal,
.rdw-image-modal {
  box-shadow: none;
}

.rdw-link-modal,
.rdw-embedded-modal {
  box-shadow: none;
  height: auto;
}

.rdw-editor-main {
  border: 1px solid rgba(0, 0, 0, 0.23);
  min-height: 150px;
  border-radius: 0px 0px 4px 4px;
  border-top: 0;
  padding: 10px;
}

/* Estilos editor texto final */

strong.eva {
  font-size: 12px;
  color: #281b65;
}

.borderRadius100 {
  border-radius: 100%;
}

.w-100-tab .MuiPaper-root.MuiPaper-elevation1 {
  width: 100% !important;
}

.react-pdf__Page__canvas {
  height: auto !important;
  width: 100% !important;
}

.ctrl-ul {
  padding: 0;
  margin: 0;
}

.ctrl-ul li {
  list-style: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.btn-ctrl-data {
  border: 1px solid #281b65;
  color: #281b65;
  font-size: 12px;
  box-sizing: border-box;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 3px;
  letter-spacing: 0.02857em;
  text-transform: capitalize;
  background-color: transparent;
  cursor: pointer;
}

.ctrl-ul .MuiMenuItem-root {
  min-height: auto;
  padding: 8px 10px !important;
}

.input-inputBool {
  display: inline-block !important;
}
.label-inputBool {
  margin-left: 5px;
  margin-right: 15px;
}

.relative {
  position: relative;
}

.search-tab-position {
  width: 100%;
  max-width: 250px;
  position: absolute;
  right: 10px;
  top: 5px;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: transparent;
  opacity: 1;
}

.ql-size-huge {
  font-size: 2.5em !important;
}

.ql-size-large {
  font-size: 1.5em !important;
}

.ql-size-small {
  font-size: 0.75em !important;
}

.ql-align-center {
  text-align: center !important;
}

.ql-align-right {
  text-align: right !important;
}

.ql-align-left {
  text-align: left !important;
}

.static .ql-align-center {
  justify-content: center !important;
}

.static .ql-align-right {
  justify-content: flex-end !important;
}

.static .ql-align-left {
  justify-content: flex-start !important;
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.calendar-item-planning {
  display: flex;
  border: none;
  width: 100%;
  border-radius: 0;
  flex-direction: column;
  padding: 10px 15px;
  width: 100%;
  max-height: 140px;
  min-height: 140px;
}

.calendar-item-planning .calendar-item-session-label {
	margin:0;
	font-size:10px; 
	font-weight:700;
}

.calendar-item-planning .calendar-item-session-p {
	margin:0;
	max-width: "100%";
	margin-bottom: 5px;
}

.calendar-main-planning {
	display: flex;
	border: none;
	width: 100%;
	border-radius: 0;
	flex-direction: column;
	padding: 10px 15px;
	width: 100%;
	background-color: #C0B5F2;
	color: #FAFAFA;
	margin-bottom: 5px;
  }

.calendar-main-planning .calendar-item-session-label {
	margin:0;
	font-size:10px; 
	font-weight:700;
}

.calendar-main-planning .calendar-item-session-p {
	margin:0;
	max-width: "100%";
	margin-bottom: 3px;
}

/* Estilos componente acordeon Datos Clínicos */

.accordion .card-body {
  padding: 0;
}

.accordion .card {
  border-radius: 0;
  border: none;
}

.accordion .card-header {
  background: transparent;
  padding: 0;
  border-bottom: 1px solid rgb(171 166 166 / 13%);
}

.accordion .header-table {
  background: rgba(208, 201, 255, 0.19);
  font-weight: 600;
  color: #281b65;
  font-size: 14px;
}

.accordion .progressTf {
  border-radius: 3px;
  height: 25px;
  background-color: #FD0202;
  background-image: none;
}

.planningTf {
  border: 0.5px solid #9CB2CD;
  border-radius: 3px;
  padding: 8px;
  background: white;
}

.border-bottom-table {
  border-bottom: 1px solid rgb(171 166 166 / 13%);
}

.bl-red {
  border-left: 4px solid #FD0202;
}

.bl-green {
  border-left: 4px solid #3EB243;
}

.bl-yellow {
  border-left: 4px solid #FDAD34;
}

.bg-red {
  background: #FD0202;
}

.bg-green {
  background: #3EB243;
}

.bg-yellow {
  background: #FDAD34;
}

.btn-table {
  border: 2px solid #281b65;
  color: #FFF;
  font-size: 12px;
  min-width: 90px;
  font-weight: 500;
  border-radius: 6px;
  background-color: #281b65;
  padding: 6px;
  letter-spacing: 1px;
}

.btn-table:hover {
  font-weight: 700;
  color: #fff;
}

.btn-table-invert {
  border: 2px solid #281b65;
  color: #281b65;
  font-size: 12px;
  min-width: 90px;
  font-weight: 500;
  border-radius: 6px;
  background-color: #FFF;
  padding: 6px;
  letter-spacing: 1px;
}

.btn-table-invert:hover {
  font-weight: 700;
  color: #281b65;
}

.percent {
  text-align: center;
  /* border: 2.5px solid #3EB243; */
  border-radius: 3px;
  height: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 45px;
  font-size: 12px;
  color: #FFF;
  font-weight: 700;
  /* background: #3EB243; */
}

/* Estilos vista seguimiento detalle datos clínicos */

.profile {
  background-color: #F9FBFF;
  border-radius: 4px;
  height: 65px;
  width: 100%;
}

.circle-profile {
  background-color:#91A4CF;
  width: 40px;
  height: 40px;
  border-radius: 100%;
}

.mini-graphics {
  border: 1px solid #DEE2EC;
  border-radius: 4px;
  height: auto;
  width: 100%;
  min-height: 200px;
}

.mini-graphics div {
  background-color: #FFF;
  /* height: 80px;
  width: 150px; */
  border-radius: 3px;
}

.mini-graphics div p {
  margin: 2px;
  font-size: 9px;
  text-align: center;
  color: #273142;
  font-weight: 700;
}



.tdd:nth-child(odd) .card{
  background-color: #FFF;
}
.tdd:nth-child(even) .card{
  background-color: #F8F8F8;
}

.tdd-contain:last-child .tdd.active{
  margin-bottom: 40px;
}

.event-allday{
  padding:2px 10px;
}

.fc-add-button {
  background-color: #281B65 !important;
  color: white !important;
  border: 1px solid #281B65 !important;
  width: 180px;
}

/* .fc-add-button:hover {
  background-color: white !important;
  color: #281B65 !important;
  border: 1px solid #CFD5DA !important;
} */