.st0 {
    fill: none;
    stroke: url(#SVGID_1_);
    stroke-width: 3;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
}

.container-loader path, .container-loader line {
    stroke-dasharray: 400;
    stroke-dashoffset: 400;
    animation: dash 2000ms linear infinite;
}

@keyframes dash {
    to {
        stroke-dashoffset: 0;
    }
}

.container-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    /* padding-top: 100px; */
}

.card-loader {
    background-color: #fff;
    border-radius: 3px;
    width: 150px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.card-loader p {
    margin-top: 20px;
    font-size: 14px;
    color: #281B65;
}

.dblock {
    display: block;
}