.progress-video {
    appearance: none;
    -webkit-appearance: none;
    width: 100%;
    position: relative;
}

.progress-video:focus {
    outline: none;
}

.progress-video::before {
    position: absolute;
    content: "";
    width: 0%;
    height: 2px;
    background: red!important;
}

.progress-video::-webkit-slider-runnable-track {
    background: #43c2af;
    height: 2px;
}

.progress-video::-webkit-slider-thumb {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    border: none;
    -webkit-appearance: none;
    background-color: blue;
    margin-top: -3.5px;
    z-index: 99;
    position: relative;
    transition: 0.5s all ease;
}

/* ocultar controles fullscreen */
/* video::-webkit-media-controls-enclosure {
    display:none !important;
} */