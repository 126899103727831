/* Estilos card vista bloque videos */ 

.card-files {
	width: 100%;
	height: auto;
	background: #FFFFFF;
    border: 1px solid #E7ECF4;
	border-radius: 4px;
	cursor: pointer;
}

.card-files:hover {
	box-shadow: 0px 5px 30px rgba(221, 221, 221, 0.6);
    font-weight: 500;
    border: 1px solid #A89ED5;
}

.screen-files {
    background-image: url(../img/bg/video1.svg);
    height: 200px;
    background-repeat: no-repeat; 
    background-position: center; 
    background-size: cover; 
    width: 100%;
    border-radius: 4px 4px 0px 0px;
    opacity: .9;
}

.screen-files:hover .icon-add, .screen-evaluations:hover .icon-add {
    opacity: 1;
    background-color: #281B65;
    color: #FFF;
}

.screen-files:hover, .screen-images:hover, .screen-records:hover {
    opacity: 1;
    background-color: rgba(238, 241, 248, 0.25);
}

.screen-images {
    /* background-image: url(../img/bg/images.svg); */
    height: 200px;
    background-repeat: no-repeat; 
    background-position: center; 
    background-size: contain; 
    width: 100%;
    border-radius: 4px 4px 0px 0px;
    opacity: .9;
}

.screen-records {
    /* background-image: url(../img/bg/records.svg); */
    height: 200px;
    background-repeat: no-repeat; 
    background-position: center; 
    background-size: cover; 
    width: 100%;
    border-radius: 4px 4px 0px 0px;
    opacity: .9;
}

.icon-screen {
	width: 35px;
	height: 35px;
	background: #FDAD34;
	border-radius: 100%;
	color: #281B65;
	font-size: 18px;
	text-align: center;
	padding: 0px;
	display: table;
}

.icon-screen:hover {
	background: #281B65;
	color: #FFFFFF;
}

.icon-add {
	border-radius: 100%;
	width: 45px;
	height: 45px;
	padding: 4px;
	font-size: 25px;
	color: #281B65;
	background-color: #EDEAFF;
	text-align: center;
	display: table;
}

.browse-files {
    background-color: #f3f6fd;
    border-radius: 10px;
    border-style: dashed;
    border-width: 2px;
    padding: 30px;
    border-color: #c8c8c9;
    width: 100%;
}

.bg-files {
    background: #FFF;
    border-radius: 4px;
    padding: 13px 5px;
    margin: 10px;
    cursor: pointer;
    height: 120px;
}

.bg-files p:last-child::after{
    overflow:hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.bg-files:hover {
    background: #97B8FF;
}

.screen-evaluations {
    background-image: url(../img/bg/test.svg);
    height: 160px;
    background-repeat: no-repeat; 
    background-position: center; 
    background-size: cover; 
    width: 100%;
    border-radius: 4px 4px 0px 0px;
    opacity: .9;
    border: 4px solid #DCE0F8;
}

.border-top {
    border-top: 1px solid #E7ECF4;
}

.custom-file-upload {
    cursor: pointer;
}