input.inputFile[type="checkbox"]:checked+.labelFile {
    background-color: #97B8FF;
}

.labelFile {
    background-color: #fff;
}


.browse-files .file-container{
    height: auto!important;
    overflow: hidden!important;
}

.bg-files{
    height: auto!important;
    text-align: center!important;
    width:100%!important;
    min-height: 170px!important;
}