.dialog-container{
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.2);
    position: fixed;
    top: 0;
    left: 0;
    margin: 0 auto;
    text-align: center;
    bottom: 0;
    right: 0;
    margin: auto;
    z-index: 9999999999;
}

.dialog{
    position: relative;
    min-height: 220px;
    background: #fcfcfc;
    box-shadow: 1px 1px 10px 5px rgba(0,0,0,0.2);
    border-radius: 10px;
    border: none;
    padding:20px;
    text-align: center;
    max-width: 400px;
    margin: 100px auto;
}

.loader {
    position: relative;
    width: 7.5em;
    height: auto;
    /* top: 50%; */
    /* left: 50%; */
    margin: 0 auto;
    /* transform: translate(-50%, -50%); */
    border-radius: 50%;
    background: #281B65;
    padding: 3px;
    transition: all 400ms ease-in-out;
  }
  @keyframes loader-spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes loader-checkmark {
    0% {
      height: 0;
      width: 0;
      opacity: 1;
    }
    20% {
      height: 0;
      width: 1.75em;
      opacity: 1;
    }
    40% {
      height: 3.5em;
      width: 1.75em;
      opacity: 1;
    }
    100% {
      height: 3.5em;
      width: 1.75em;
      opacity: 1;
    }
  }
  @keyframes loader-errormark {
    0% {
      height: 0;
    }
    100% {
      height: 3.5em;
    }
  }
  .loader .circle-loader {
    background: #281B65;
    border: 3px solid transparent;
    border-top-color: #fff;
    animation: loader-spin 1.2s infinite linear;
    position: relative;
    display: inline-block;
    vertical-align: top;
    border-radius: 50%;
    width: 7em;
    height: 7em;
    transition: background-color 400ms ease-in-out;
  }
  .loader .circle-loader .checkmark {
    display: none;
  }
  .loader .circle-loader .checkmark:after {
    opacity: 1;
    height: 3.5em;
    width: 1.75em;
    transform-origin: left top;
    border-right: 3px solid #fff;
    border-top: 3px solid #fff;
    content: '';
    left: 1.4583333333em;
    top: 3.5em;
    position: absolute;
    animation-duration: 800ms;
    animation-timing-function: ease;
    animation-name: loader-checkmark;
    transform: scaleX(-1) rotate(135deg);
  }
  .loader .circle-loader .errormark {
    display: none;
  }
  .loader .circle-loader .errormark:before, .loader .circle-loader .errormark:after {
    content: '';
    opacity: 1;
    height: 0;
    width: 0;
    transform-origin: center top;
    border-right: 3px solid #fff;
    top: 2.3333333333em;
    position: absolute;
    animation-duration: 400ms;
    animation-timing-function: ease;
    animation-name: loader-errormark;
    animation-fill-mode: forwards;
  }
  .loader .circle-loader .errormark:before {
    transform: rotate(-45deg);
    left:32px;
    top: 34px;
  }
  .loader .circle-loader .errormark:after {
    animation-delay: 400ms;
    transform: rotate(45deg);
    right: 32px;
    top: 34px;
  }
  .loader.load-complete .circle-loader, .loader.load-fail .circle-loader {
    -webkit-animation: none;
    animation: none;
    border-color: transparent;
  }
  .loader.load-complete .circle-loader .checkmark {
    display: block;
  }
  .loader.load-fail {
    background: #FDAD34;
  }
  .loader.load-fail .circle-loader {
    background: #FDAD34;
  }
  .loader.load-fail .circle-loader .errormark {
    display: block;
  }
  