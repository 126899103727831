@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?4zpknx');
  src:  url('fonts/icomoon.eot?4zpknx#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?4zpknx') format('truetype'),
    url('fonts/icomoon.woff?4zpknx') format('woff'),
    url('fonts/icomoon.svg?4zpknx#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-plans:before {
  content: "\e966";
}
.icon-media-category:before {
  content: "\e967";
}
.icon-calendar:before {
  content: "\e968";
}
.icon-library-media:before {
  content: "\e969";
}
.icon-sales:before {
  content: "\e965";
}
.icon-resource:before {
  content: "\e963";
}
.icon-services:before {
  content: "\e964";
}
.icon-heart-outlined:before {
  content: "\e961";
}
.icon-heart-filled:before {
  content: "\e962";
}
.icon-data-table:before {
  content: "\e960";
}
.icon-question:before {
  content: "\e95f";
}
.icon-email-site:before {
  content: "\e958";
  color: #707b8e;
}
.icon-phone-site:before {
  content: "\e959";
  color: #707b8e;
}
.icon-location-site:before {
  content: "\e95a";
  color: #707b8e;
}
.icon-feedback:before {
  content: "\e950";
}
.icon-treadmill:before {
  content: "\e951";
}
.icon-video:before {
  content: "\e952";
}
.icon-close:before {
  content: "\e900";
}
.icon-user:before {
  content: "\e90d";
}
.icon-pause:before {
  content: "\e918";
}
.icon-cicloergometro:before {
  content: "\e957";
}
.icon-trending:before {
  content: "\e95e";
}
.icon-branch:before {
  content: "\e95c";
}
.icon-specialties:before {
  content: "\e95d";
}
.icon-up-arrow:before {
  content: "\e95b";
}
.icon-4:before {
  content: "\e919";
}
.icon-5:before {
  content: "\e92c";
}
.icon-6:before {
  content: "\e956";
}
.icon-1:before {
  content: "\e953";
}
.icon-2:before {
  content: "\e954";
}
.icon-3:before {
  content: "\e955";
}
.icon-jpg .path1:before {
  content: "\e938";
  color: rgb(255, 255, 255);
}
.icon-jpg .path2:before {
  content: "\e939";
  margin-left: -1.01953125em;
  color: rgb(202, 209, 216);
}
.icon-jpg .path3:before {
  content: "\e93a";
  margin-left: -1.01953125em;
  color: rgb(226, 229, 231);
}
.icon-jpg .path4:before {
  content: "\e93b";
  margin-left: -1.01953125em;
  color: rgb(30, 136, 229);
}
.icon-jpg .path5:before {
  content: "\e93c";
  margin-left: -1.01953125em;
  color: rgb(255, 255, 255);
}
.icon-jpg .path6:before {
  content: "\e93d";
  margin-left: -1.01953125em;
  color: rgb(255, 255, 255);
}
.icon-jpg .path7:before {
  content: "\e93e";
  margin-left: -1.01953125em;
  color: rgb(255, 255, 255);
}
.icon-jpg .path8:before {
  content: "\e93f";
  margin-left: -1.01953125em;
  color: rgb(202, 209, 216);
}
.icon-pdf .path1:before {
  content: "\e940";
  color: rgb(255, 255, 255);
}
.icon-pdf .path2:before {
  content: "\e941";
  margin-left: -1.01953125em;
  color: rgb(202, 209, 216);
}
.icon-pdf .path3:before {
  content: "\e942";
  margin-left: -1.01953125em;
  color: rgb(226, 229, 231);
}
.icon-pdf .path4:before {
  content: "\e943";
  margin-left: -1.01953125em;
  color: rgb(255, 30, 1);
}
.icon-pdf .path5:before {
  content: "\e944";
  margin-left: -1.01953125em;
  color: rgb(255, 255, 255);
}
.icon-pdf .path6:before {
  content: "\e945";
  margin-left: -1.01953125em;
  color: rgb(255, 255, 255);
}
.icon-pdf .path7:before {
  content: "\e946";
  margin-left: -1.01953125em;
  color: rgb(255, 255, 255);
}
.icon-pdf .path8:before {
  content: "\e947";
  margin-left: -1.01953125em;
  color: rgb(202, 209, 216);
}
.icon-png .path1:before {
  content: "\e948";
  color: rgb(255, 255, 255);
}
.icon-png .path2:before {
  content: "\e949";
  margin-left: -1.01953125em;
  color: rgb(202, 209, 216);
}
.icon-png .path3:before {
  content: "\e94a";
  margin-left: -1.01953125em;
  color: rgb(226, 229, 231);
}
.icon-png .path4:before {
  content: "\e94b";
  margin-left: -1.01953125em;
  color: rgb(70, 50, 164);
}
.icon-png .path5:before {
  content: "\e94c";
  margin-left: -1.01953125em;
  color: rgb(255, 255, 255);
}
.icon-png .path6:before {
  content: "\e94d";
  margin-left: -1.01953125em;
  color: rgb(255, 255, 255);
}
.icon-png .path7:before {
  content: "\e94e";
  margin-left: -1.01953125em;
  color: rgb(255, 255, 255);
}
.icon-png .path8:before {
  content: "\e94f";
  margin-left: -1.01953125em;
  color: rgb(202, 209, 216);
}
.icon-chat:before {
  content: "\e901";
}
.icon-bike:before {
  content: "\e902";
}
.icon-rowing:before {
  content: "\e903";
}
.icon-energi:before {
  content: "\e904";
}
.icon-sequential:before {
  content: "\e905";
}
.icon-walkfes:before {
  content: "\e906";
}
.icon-star:before {
  content: "\e907";
}
.icon-tools:before {
  content: "\e908";
}
.icon-arrow-point-to-right:before {
  content: "\e909";
}
.icon-power:before {
  content: "\e90a";
}
.icon-training-therapist:before {
  content: "\e90b";
}
.icon-trash:before {
  content: "\e90c";
}
.icon-patient:before {
  content: "\e90e";
}
.icon-sensor:before {
  content: "\e90f";
}
.icon-therapist:before {
  content: "\e910";
}
.icon-home:before {
  content: "\e911";
}
.icon-comment:before {
  content: "\e912";
}
.icon-form:before {
  content: "\e913";
}
.icon-heart:before {
  content: "\e914";
}
.icon-help:before {
  content: "\e915";
}
.icon-winner:before {
  content: "\e916";
}
.icon-pain:before {
  content: "\e917";
}
.icon-alert:before {
  content: "\e91a";
}
.icon-training:before {
  content: "\e91b";
}
.icon-folder_upload:before {
  content: "\e91c";
}
.icon-folder_down:before {
  content: "\e91d";
}
.icon-arrow-point-to-left:before {
  content: "\e91e";
}
.icon-checked:before {
  content: "\e91f";
}
.icon-phone:before {
  content: "\e920";
}
.icon-sms:before {
  content: "\e921";
}
.icon-balance:before {
  content: "\e922";
}
.icon-flexibility:before {
  content: "\e923";
}
.icon-march:before {
  content: "\e924";
}
.icon-calendar-user:before {
  content: "\e925";
}
.icon-sitting-stationary:before {
  content: "\e926";
}
.icon-stationary:before {
  content: "\e927";
}
.icon-stationary-sitting:before {
  content: "\e928";
}
.icon-protocol:before {
  content: "\e929";
}
.icon-archive:before {
  content: "\e92a";
}
.icon-notes:before {
  content: "\e92b";
}
.icon-calendar-therapist:before {
  content: "\e92d";
}
.icon-training-user:before {
  content: "\e92e";
}
.icon-notification:before {
  content: "\e92f";
}
.icon-clock:before {
  content: "\e930";
}
.icon-graphic:before {
  content: "\e931";
}
.icon-like:before {
  content: "\e932";
}
.icon-evaluation:before {
  content: "\e933";
}
.icon-stimulation:before {
  content: "\e934";
}
.icon-bipedestation:before {
  content: "\e935";
}
.icon-play:before {
  content: "\e936";
}
.icon-plus:before {
  content: "\e937";
}
.icon-export:before {
  content: "\e91c";
}
.icon-search:before {
  content: "\e986";
}
.icon-google:before {
  content: "\ea88";
}
.icon-facebook:before {
  content: "\ea90";
}
.icon-instagram:before {
  content: "\ea92";
}
.icon-linkedin:before {
  content: "\eaca";
}
