:root {
    --color-calendar: #393275;
    --color-calendar-light: #FDAD3420;
    --color-calendar-hover: #FDAD34;
    --color-calendar-fc-event: #39327540;
    --color-calendar-border-table: #FDAD34;
    --color-calendar-buttons: #FDAD34;
    --color-text: #13102E;
    --color-border: #13102E15;
    --color-border-today: #281b65;
    --color-border-today-alpha: #C7D9FF;
    --padding-event-timegrid: 10px;
    --color-tf: #281B65;
}

/* events */

.fc-event-main {
    display: flex;
    flex-direction: column;
}

.fc-h-event {
    border: none;
    padding: 10px;
}

.fc-daygrid-event .fc-event_int {
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 3px;
    line-height: 1.4;
    border: none;
    cursor: move;
    font-size: 14px;
    margin: 0;
    padding: 7px 10px;
    background-color: #ccc;
    color: #4632A4 !important;
    z-index: auto;
    box-sizing: border-box;
    align-items: flex-start;
    width: 100%;
}

.fc-day-grid-event .fc-content {
    white-space: normal !important;
}

.fc-button {
    background: #FFF;
    border: 1px solid #ced4da;
    color: #57494d;
    text-transform: capitalize;
    border-radius: 3px !important;
}

.fc button {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    height: 33px;
    padding: 0 9px;
    font-size: 14px;
    cursor: pointer;
}

.fc-button-primary:disabled {
    color: var(--color-text) !important;
    background-color: #FFFFFF !important;
    border-color: #DEE2EC !important;
}

.fc-button-primary:hover {
    color: #fff !important;
    background-color: var(--color-tf) !important;
    border-color: var(--color-tf) !important;
}

.fc-button:disabled {
    opacity: 0.65;
}

.fc-unthemed td.fc-today {
    background-color: #F5F3FF;
    position: relative;
}

.fc-unthemed td.fc-today .fc-day-number {
    color: var(--color-calendar) !important;
    padding-left: 4px;
    padding-top: 2px;
    font-weight: 600;
}

.fc-day-number {
    float: none !important;
    font-size: 12px !important;
    color: #3c4043 !important;
}

.fc-day-header span {
    font-size: 14px;
    color: var(--color-calendar);
}

.fc-unthemed th,
.fc-unthemed td,
.fc-unthemed thead,
.fc-unthemed tbody,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-row,
.fc-unthemed .fc-content,
.fc-unthemed .fc-popover,
.fc-unthemed .fc-list-view,
.fc-unthemed .fc-list-heading td {
    border-color: #dadce0;
}

.fc-past .fc-day-number {
    color: var(--color-calendar) !important;
}

.fc-left h2,
.fc-center h2,
.fc-right h2 {
    font-size: 16px;
    font-weight: 600;
    line-height: 30px;
    text-transform: uppercase;
    padding-bottom: 3px;
}

.fc-toolbar.fc-header-toolbar {
    margin-bottom: 11px;
}

.fc th.fc-widget-header {
    font-size: 14px;
    line-height: 20px;
    padding: 10px 0;
    font-weight: 600;
    text-transform: uppercase;
}

.fc-unthemed td.fc-today .fc-day-number::after {
    content: '';
    width: 20px;
    height: 20px;
    background: #FDAD34;
    position: absolute;
    top: 2px;
    left: 2px;
    z-index: -1;
    border-radius: 100px;
}

/*  Buttons */

.fc .fc-button-primary {
    color: #281B65;
    background-color: #fff;
    border-color: #CFD5DA;
    padding: 5px 15px;
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 500;
}

.fc button {
    height: auto;
}

.fc button:focus {
    outline: 0 !important;
    box-shadow: 0 0 0 transparent !important;
    ;
}

.fc-button {
    border-radius: 2px !important;
}

.fc .fc-button-active {
    color: #fff;
    background-color: var(--color-tf) !important;
    border-color: var(--color-tf) !important;
}

.fc .fc-theme-standard td,
.fc-theme-standard th {
    border: 1px solid var(--color-calendar-light);
}

.fc .fc-scrollgrid.fc-scrollgrid-liquid {
    border-radius: 5px;
}

.fc-daygrid-day-frame {
    min-height: 0 !important;
}

.fc .fc-dayGridMonth-view .fc-daygrid-day.fc-day.fc-day-today {
    /* vista mensual */
    background-color: var(--color-border-today-alpha);
}

.fc .fc-timeGridWeek-view .fc-daygrid-day.fc-day.fc-day-today,
.fc .fc-timeGridDay-view .fc-daygrid-day.fc-day.fc-day-today {
    /* vista semanal */
    background-color: var(--color-border-today-alpha);
}

.fc .fc-daygrid-day-number {
    top: 8px;
    right: 3px;
    position: absolute;
    z-index: 999999999;
}

.fc-daygrid-day-number {
    background-color: #fff !important;
    border-radius: 100px !important;
    height: 20px !important;
    width: 20px !important;
    color: var(--color-calendar) !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    font-size: 11px !important;
}

.fc .fc-daygrid-day.fc-day-today {
    background: #EEF2FF;
}

.fc .fc-daygrid-day.fc-day-today .fc-daygrid-day-number {
    background-color: #fff;
    border-radius: 100px;
    height: 20px;
    width: 20px;
    color: var(--color-calendar);
    display: flex;
    justify-content: center;
    align-items: center;
}

.fc .fc-daygrid-day-top {
    position: relative;
}

.fc .fc-timegrid-col.fc-day-today {
    background-color: #ced6e030;
    /* background-color: var(--color-calendar-light); */
}

.fc-scrollgrid-shrink-cushion {
    color: #9CB2CD !important;
}

.fc .fc-timegrid-slot-minor {
    border: none !important;
}

.fc .fc-timegrid-slot {
    height: 53px !important;
}

/* .fc-timegrid-col.fc-day {
    border: 1px solid var(--color-calendar-border-table, #ccc)!important;
} */

.fc-toolbar-title {
    font-size: 14px !important;
    display: inline-block !important;
    color: var(--color-text) !important;
    min-width: 150px !important;
    text-align: center !important;
}

.fc-header-toolbar.fc-toolbar.fc-toolbar-ltr {
    padding: 15px 0;
    /* background: #EFF6FF; */
    /* background: #FFF; */
    margin: 0 !important;
}

.fc-prev-button,
.fc-next-button {
    border-radius: 11px !important;
    display: inline-flex !important;
    justify-content: center !important;
    align-items: center !important;
    width: 36px !important;
    height: 36px !important;
    border-color: #DEE2EC !important;
}

.fc-icon-chevron-left:hover:before,
.fc-icon-chevron-right:hover:before {
    color: #FFF;
}

.fc-today-button {
    height: 36px !important;
    width: 71px !important;
    border-radius: 11px !important;
    color: var(--color-text) !important;
}

.fc-button-primary {
    border-color: #DEE2EC !important;
    height: 36px !important;
    border-radius: 4px !important;
    display: inline-flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.fc-col-header-cell-cushion {
    color: #393275 !important;
    font-size: 14px !important;
}

.fc th.fc-day {
    padding: 8px;
    border: none;
    border-bottom: 1px solid var(--color-calendar-light);
    text-transform: capitalize;
}

::-webkit-scrollbar-thumb {
    background-color: rgba(223, 222, 226, 0.66);
    ;
    border-radius: 20px;
}

.fc-timegrid-slot-label-cushion.fc-scrollgrid-shrink-cushion {
    color: #393275 !important;
    font-size: 11px !important;
}

.fc .fc-timegrid-axis-cushion {
    font-size: 11px !important;
}

.ccalendar td,
.ccalendar th,
.ccalendar tr {
    border-color: var(--color-border);
}

.ccalendar table {
    border-color: var(--color-border);
    border-collapse: collapse !important;
}

.fc-event {
    /* background: var(--color-calendar-fc-event);
    border-color: var(--color-calendar-fc-event); */
}

.fc-timegrid-event {
    padding: var(--padding-event-timegrid);
}


.fc .fc-bg-event {
    opacity: 0.6 !important;
}

.fc .fc-scrollgrid-section-sticky>* {
    background-color: transparent;
}

.fc-view-harness {
    background-color: #FFF;
}

.fc .fc-non-business {
    background-color: #f8f7fc
        /* #281B6515; */
}