/* Estilos vista terapeuta */

.shadow-list {
	margin-top: -77px;
    height: 60px;
	width: auto;
	background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
	position: relative;
}

/* Estilos scroll */

.scroll-list {
	max-height: 75vh;
	overflow-y: auto;
}

.scroll-list-2 {
	max-height: 70vh;
	overflow-y: auto;
}

/* width */
::-webkit-scrollbar {
	width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: rgba(223, 222, 226, 0.66);
	border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: rgba(211, 203, 255, 0.5);
	cursor: pointer;
}

/* Scrollbar Style */ 

#style-1::-webkit-scroll-list-track {
	border-radius: 2px;
}

#style-1::-webkit-scroll-list {
	width: 5px;
	background-color: #F7F7F7;
}

#style-1::-webkit-scroll-list-thumb {
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #BFBFBF;
}


/* Estilos card vista terapeuta */ 

.card-records {
	width: 100%;
	height: auto;
	background: #FFFFFF;
	box-shadow: 0px 5px 30px rgba(221, 221, 221, 0.6);
	border-radius: 4px;
	cursor: pointer;
	overflow:hidden;
}

.card-records:hover {
	box-shadow: 0px 1px 30px rgba(221, 221, 221, 1.6);
	border: 1px solid #A89ED5;
}

.card-records p:nth-child(2) {
	color: #979797;
}

.card-patient {
	width: 100%;
	height: 75px;
	background: #FFFFFF;
	box-shadow: 0px 5px 30px rgba(221, 221, 221, 0.6);
	border-radius: 4px;
	cursor: pointer;
	padding: 0 10px;
}

.card-patient:hover, .card-records:hover {
	box-shadow: 0px 1px 30px rgba(221, 221, 221, 0.6);
	font-weight: 500;
	border: 2px solid #6a5da5;
}

.card-patient-active,
.card-records-active,
.card-video-active {
	box-shadow: 0px 1px 30px rgba(221, 221, 221, 1.6);
	font-weight: 500;
	border: 2px solid #6a5da5 !important;
}

.card-patient-active .icon-arrow-point-to-right::before,
.card-patient-active .icon-tools::before,
.card-records-active .icon-arrow-point-to-right::before,
.card-records-active .icon-tools::before {
	color: #281B65;
}

.card-patient p:nth-child(1) {
	font-size: 16px;
	line-height: 21px;
	letter-spacing: 0.850962px;
	color: #281B65;
}

.card-patient p:nth-child(3) {
	font-size: 14px;
	line-height: 18px;
	letter-spacing: 0.850962px;
	color: #FDAD34;
}

/*  Estilos icono color intercalado efecto card*/

.card-records:nth-child(odd) .icon {
	background-color: #FDAD34;
}

.card-records:nth-child(even) .icon {
	background-color: #281B65;
}
