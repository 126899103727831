.sl-alert1, .sl-alert2, .sl-alert3, .sl-alert4 {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 400px;
    color: white;
    text-align: left;
    padding: 15px 10px;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 4px;
    z-index: 9999999999999999!important;
    font-size: 14px;
    background-color: #EB6464;
}

.sl-alert2 {
    top: 91px;
}

.sl-alert3 {
    top: 167px;
}

.sl-alert4 {
    top: 243px;
}

.sl-alert.danger {
    background-color: #EB6464;
}

.sl-alert.success {
    background-color: #67B963;
}

.sl-alert.info {
    background-color: #039DE3;
}