#chart {
    width: 100%;
    height: auto;
    min-height: 580px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    padding-top: 20px;
}

#chart .labely {
    position: absolute;
    right: 20px;
    bottom:0;
    font-weight: 500;
    color: #281B65;
}

#chart .value-y{
    color: #281B65;
    font-weight: 400;
    text-align: right!important;
}

#chart .labelx {
    position: absolute;
    left: -10px;
    top: -10px;
    font-weight: 500;
    color: #281B65;
}

#chart .xLineContainer {
    width: 30px;
    height: 500px;
    position: relative;
    border-right: 1px solid #281B65;
}

#chart .hidden {
    flex: 1;
    overflow: auto;
}

.graph .labels.x-labels {
    text-anchor: middle;
}

.graph .labels.y-labels {
    text-anchor: end;
}

.graph {
    border-bottom: 1px solid #281B65;
}

.graph .grid {
    stroke: #ccc;
    stroke-dasharray: 0;
    stroke-width: 1;
}

.labels {
    font-size: 13px;
}

.graph .big-text {
    fill: #fff!important;
}

.graph .normal-text {
    fill: #281B65!important;
}

.label-title {
    /* font-weight: bold; */
    /* text-transform: uppercase; */
    /* font-size: 12px; */
    /* fill: black; */
}

.data {
    stroke-width: 1;
}

.data rect:hover, .data polygon:hover {
    opacity: 0.9;
}

.data rect {
    transition: all 0.6s !important;
}