
  
  input.input-radio-ope99n {
    display: none;
  }

  .rotate-icons{
      transition: all .2s ease-in;
  }

  .input-radio-open:not(:checked) + header label .rotate-icons {
    transform: rotate(0deg);
  }

  .input-radio-open:checked +  header label .rotate-icons {
    transform: rotate(180deg);
  }
  
  .input-radio-open:not(:checked) ~ .element__content {
    height: 0;
    opacity: 0;
    overflow: hidden;
  }

  .input-radio-open:checked ~ .element__content {
    opacity: 1;
    overflow: auto;
  }
  
  .accordeon {
    margin: 0;
  }

  .header__title {
    width: 100%;
    padding: 10px 0 6px 0;
    border-bottom: 1px solid #FDAD34;
    background-color: white;
    margin: 0;
    cursor: pointer;

    font-weight: 400;
    font-size: 12px;
    letter-spacing: 0.850962px;

    color: #182024;
  }

  .content__wrapper {
    color: #979797;
    margin: 10px 5px;
    font-size: 14px;
  }

