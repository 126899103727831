.downl-container{
    height: 200px;
	min-width: 340px;
    width: auto;
    overflow-y: hidden;
    position: relative;
}
.downl {
	background: rgb(255, 255, 255, 0);
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	width: 100%;
	z-index: 999999;
	bottom: 0;
}

.downl p{
	background: #ccc;
	bottom: -16px;
	position: absolute;
	width: 100%;
	padding: 8px 20px;
    color: #281B65;
    cursor: pointer;
}

.adjunto{
	margin: 0 20px;
}

.adjunto svg path,.react-input-emoji--button svg{
	fill: #281B65
}

.adjunto:hover svg path,.react-input-emoji--button:hover svg{
	fill: #FF9A03
}