/* Style Navbar */

.nav-t ul li a {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 17px;
    color: #000000;
    padding: 10px 30px !important;
}

.nav-t ul li a:hover {
    font-weight: 600;
    cursor: pointer;
}

.fijo {
    position: fixed !important;
    width: 100%;
    z-index: 999;
}

/* Style Banner */

.bg-banner {
    /* background-image: url(../assets/img/bg/bg-banner.svg); */
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    padding-top: 77px;
    background-color: #FFF;
}

.text-banner h1 {
    color:#273142;
    font-size: 48px;
    font-weight: 700;
}

.text-banner p {
    color:#273142;
    font-size: 18px;
}

/* Style Especialidades */

.text-especialidades h2 {
    color: #273C63;
    font-size: 42px;
    font-weight: 700;
    letter-spacing: 2px;
}

.text-especialidades p {
    color: #273C63;
    font-size: 18px;
    font-weight: 400;
    margin: 0;
    letter-spacing: 1px;
}

/* Style Card */

#especialidades .card {
    background: #FFFFFF;
    box-shadow: 0px 32px 84px rgba(14, 38, 124, 0.17);
    border-radius: 5px;
    border: none;
    height: 150px;
    width: 180px;
    justify-content: center;
    margin-bottom: 10px;
}

#especialidades .card h2 {
    color: #273C63;
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
}

#especialidades .card p {
    color: #707B8E;
    font-size: 12px;
    margin: 0;
    font-family: 'Poppins', sans-serif;
}

#sucursales .card {
    border-radius: 5px;
    border: none;
    width: 100%;
    justify-content: center;
    text-align: left;
    padding: 20px;
    margin-bottom: 10px;
    box-shadow: 0 0 15px rgb(0 0 0 / 5%);
}

#sucursales .card h2 {
    color: #273C63;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    margin: 0;
    padding-bottom: 10px;
    font-weight: 600;
}

#sucursales .card p {
    color: #707B8E;
    font-size: 14px;
    margin: 0;
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
}

#sucursales .card p span{
    font-weight: 500;
}

.sub-title {
    text-align: center;
    justify-content: center;
    border-radius: 5px 5px 0 0;
    margin: 0;
}

.sub-title p {
    margin: 0;
    font-size: 16px;
    color: #FFF;
}

/* Style Equipo */

.equipo h6 {
    color: #273142;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    margin: 0;
}

.equipo p {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
    color: #273C63;
}

.equipo p:last-child {
    color: #707B8E;
    font-size: 12px;
    font-weight: 300;
    margin: 0;
}

ul {
    list-style: none;
}

.perfil-box {
    background-color: #D7E3F1;
    height: 90px;
    width: 90px;
    border-radius: 100%;
    margin: 0 auto;
    object-fit: cover;
}

/* Style Formulario */

.agenda form label {
    font-size: 12px !important;
}

.agenda form, .agenda .form-control {
    width: 100%;
}

.agenda .form-group {
    position: relative;
}

.agenda .alert-form {
    position: absolute;
    right: 0px;    
    color: red;
    font-size: 12px;
    top: 15px;
    display: none;
}

.agenda2 form label {
    font-size: 16px !important;
    font-weight: 500;
    color: #273C63;
}

.agenda2 form, .agenda2 .form-control {
    width: 100%;
}

.agenda2 .form-control {
    width: 100%;
    font-size: 16px;
    border: none;
    background: white;
    border: 1.5px solid #e7ecf4;
    box-shadow: 0px 1px 70px #E7ECF4;
}

.agenda2 .form-group {
    position: relative;
}

.agenda2 .alert-form {
    position: absolute;
    right: 0px;    
    color: red;
    font-size: 12px;
    top: 15px;
    display: none;
}

#success,#error{
    display: none;
}

.text-red {
    color: #fa0505;
}

.bg-formulario {
    background-color: #F5F7FA;
}

/* Style Button */

.btn-homepage {
	border: 1px solid transparent;
	color: #FFF;
	padding: 6px 16px;
	font-size: 1rem;
	min-width: 100px;
	box-sizing: border-box;
	font-family: 'Poppins', sans-serif;
	font-weight: 500;
	line-height: 1.75;
	border-radius: 3px;
	letter-spacing: 0.02857em;
	text-transform: capitalize;
}

.btn-homepage:hover {
    transition: background-color .5s;
}

/* Style Futter */

.r-s img:hover {
    background-color: #E3E6EF;
    border-radius: 100%;
}

.go-up {
    cursor:pointer;
    position:fixed;
    bottom:10px;
    right:10px;
    z-index:2;
}

/* Style Tabla */

.table-homepage {
    font-family: 'Poppins', sans-serif;
}

.border-top-none {
    border-top: none !important;
}

.fz-16 {
    font-size: 16px !important;
}

.bg-table-homepage {
    background-color: #F8F8F8;
}

.border-homepage {
    border: 1px solid rgba(0,0,0,.125);
    border-radius: 5px;
    padding: 20px;

}

/* Style No Mobile */

.noMobile {
    width: 100%;
    text-align: center;
    height: 100%;
}

.noMobile .box {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.noMobile .box h4 {
    color: #281B65;
    font-size: 36px;
    font-weight: 600;
}

.noMobile .box p {
    color: #3F567A;
    font-size: 21px;
}

.noMobile .box span {
    color: #3F567A;
    font-size: 18;
    font-weight: 100;
    margin-top: -15px;
}

@media (min-width: 992px) {
    .mobile {
        display: none !important;
    }
}
@media (max-width: 992px) {
    .no-mobile {
        display: none !important;
    }
}





.loader-static {
    position: absolute;
    width: 40px;
    height: 40px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .loader-static .center {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    animation: center 3.2s ease-in-out infinite;
  }
  .loader-static .item {
    position: absolute;
    width: 10px;
    height: 10px;
    top: 15px;
    left: 0;
    right: 0;
    margin: auto;
    background: #3c57dd;
    border-radius: 50%;
  }
  
  .item-1 {
    animation: anim-1 3.2s ease-in-out infinite 0.2s;
    animation-fill-mode: backwards;
  }
  
  @keyframes anim-1 {
    0%, 60%, 100% {
      transform: rotate(45deg) translateX(40px) scale(1);
    }
    10%, 50% {
      transform: rotate(45deg) translateX(0) scale(1.5);
    }
  }
  .item-2 {
    animation: anim-2 3.2s ease-in-out infinite 0.4s;
    animation-fill-mode: backwards;
  }
  
  @keyframes anim-2 {
    0%, 60%, 100% {
      transform: rotate(90deg) translateX(40px) scale(1);
    }
    10%, 50% {
      transform: rotate(90deg) translateX(0) scale(1.5);
    }
  }
  .item-3 {
    animation: anim-3 3.2s ease-in-out infinite 0.6s;
    animation-fill-mode: backwards;
  }
  
  @keyframes anim-3 {
    0%, 60%, 100% {
      transform: rotate(135deg) translateX(40px) scale(1);
    }
    10%, 50% {
      transform: rotate(135deg) translateX(0) scale(1.5);
    }
  }
  .item-4 {
    animation: anim-4 3.2s ease-in-out infinite 0.8s;
    animation-fill-mode: backwards;
  }
  
  @keyframes anim-4 {
    0%, 60%, 100% {
      transform: rotate(180deg) translateX(40px) scale(1);
    }
    10%, 50% {
      transform: rotate(180deg) translateX(0) scale(1.5);
    }
  }
  .item-5 {
    animation: anim-5 3.2s ease-in-out infinite 1s;
    animation-fill-mode: backwards;
  }
  
  @keyframes anim-5 {
    0%, 60%, 100% {
      transform: rotate(225deg) translateX(40px) scale(1);
    }
    10%, 50% {
      transform: rotate(225deg) translateX(0) scale(1.5);
    }
  }
  .item-6 {
    animation: anim-6 3.2s ease-in-out infinite 1.2s;
    animation-fill-mode: backwards;
  }
  
  @keyframes anim-6 {
    0%, 60%, 100% {
      transform: rotate(270deg) translateX(40px) scale(1);
    }
    10%, 50% {
      transform: rotate(270deg) translateX(0) scale(1.5);
    }
  }
  .item-7 {
    animation: anim-7 3.2s ease-in-out infinite 1.4s;
    animation-fill-mode: backwards;
  }
  
  @keyframes anim-7 {
    0%, 60%, 100% {
      transform: rotate(315deg) translateX(40px) scale(1);
    }
    10%, 50% {
      transform: rotate(315deg) translateX(0) scale(1.5);
    }
  }
  .item-8 {
    animation: anim-8 3.2s ease-in-out infinite 1.6s;
    animation-fill-mode: backwards;
  }
  
  @keyframes anim-8 {
    0%, 60%, 100% {
      transform: rotate(360deg) translateX(40px) scale(1);
    }
    10%, 50% {
      transform: rotate(360deg) translateX(0) scale(1.5);
    }
  }
  @keyframes center {
    0%, 10%, 90%, 100% {
      transform: scale(0.7);
    }
    45%, 55% {
      transform: scale(1);
    }
  }

  .animate-more{
      /* transition: max-height .2s ease-in-out; */
      transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
  }