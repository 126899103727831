.ql-picker-label{
    display: inline-flex!important;
    align-items: center;
}

/* .ql-container{
    border: 1px solid rgb(142, 11, 11)!important;
} */


.ql-size-huge, .ql-size-large, .ql-size-normal, .ql-size-small{
    border-radius:5px;
    padding: 5px 10px;
}